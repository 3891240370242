import cookie from 'react-cookies';
import { getLoggedInUser, getValidSession } from 'data/users'
import { saveInternal, saveSettings } from 'data/login';
import { setPrefix, set } from 'data/storage';

require("moment/min/locales.min");
var moment = require('moment')

const changeLangAction = 'changeLanguage'

//Get/Set Settings from server
const GetSettings = 'getSettings'
const SAVESETTINGS = 'saveSettings'
const SAVED = 'savedSettings'
const NOSETTINGS = 'noSettings'
const reset = 'resetSettings'

export const resetSettings = () => {
	return async (dispatch, getState) => {
		dispatch({
			type: reset,
			user: getState().settings.user
		})
		dispatch(await saveSettingsOnServ())
		dispatch(await getSettings())
	}
}
export const saveOnServ = (user) => {
	return async (dispatch) => {
		var saved = await saveSettings(user);
		dispatch({
			type: SAVESETTINGS,
			saved: saved ? true : false
		})
	}
}
export const saveSettingsOnServ = () => {
	return async (dispatch, getState) => {
		let user = getState().settings.user;
		let s = getState().settings;

		let settings = {
			language: s.language
		}

		user.internal.climaidinsight.settings = settings;
		var saved = await saveInternal(user.internal, user.uuid)

		dispatch({
			type: SAVESETTINGS,
			saved: saved ? true : false
		})
	}
}
export const getSettings = async () => {
	return async (dispatch, getState) => {
		var sessionCookie = cookie.load('SESSION') ? cookie.load('SESSION') : null
		if (sessionCookie) {
			let vSession = await getValidSession().then(rs => rs.status)
			if (vSession === 200) {
				let exp = moment().add('1', 'day')
				cookie.save('SESSION', sessionCookie, { path: '/', expires: exp.toDate() })
				setPrefix(sessionCookie.userID)
			} else {
				return cookie.remove('SESSION')
			}
		}

		var userId = cookie.load('SESSION') ? cookie.load('SESSION').userID : 0
		var user = userId !== 0 ? await getLoggedInUser() : null

		if (user) {
			user.internal = user.internal || {}
			user.internal.climaidinsight = user.internal.climaidinsight || {}
			let settings = user.internal.climaidinsight.settings = user.internal.climaidinsight.settings || {}

			if (settings) {
				moment.locale(settings.language === 'en' ? 'en-gb' : settings.language);

				dispatch({
					type: GetSettings,
					settings: {
						...settings,
					},
					user
				})
			} else {
				moment.locale('da')

				let s = {
					...getState().settings,
				}

				dispatch({
					type: NOSETTINGS,
					loading: false,
					user,
					settings: s
				})
			}
		}
	}
}

export const finishedSaving = () => {
	return {
		type: SAVED,
		saved: false
	}
}
let autoheight = Math.round((window.innerHeight - 70 - 48 - 30 - 64 - 56 - 30 - 56 - 30) / 49) + ' - auto'
export let initialState = {
	weekendColor: 'red',
	periods: [{
		id: 0,
		menuId: 0,
		raw: true,
		timeType: 1,
		chartType: 3,
		hide: false
	}],
	cookies: true,
	language: 'da',
	theme: 0,
	trp: 10,
	loading: true,
	saved: false,
	rowsPerPageOptions: [autoheight, 5, 7, 8, 10, 15, 20, 25, 50, 100],
	snackbarLocation: 'left',
	hoverTime: 1000,
	dsTheme: 0,
	colorTheme: 'blue'
}
export const settings = (state = initialState, action) => {
	switch (action.type) {
		case 'RESET_APP':
			return initialState
		case reset:
			return Object.assign({}, state, { ...initialState, user: action.user, cookies: false })
		case SAVED:
			return Object.assign({}, state, { saved: action.saved })
		case NOSETTINGS:
			return Object.assign({}, state, { ...action.settings, loading: false, user: action.user })
		case GetSettings: {
			// let periods = setDates(action.settings.periods)
			set('settings', action.settings)
			return Object.assign({}, state, { ...action.settings, /* periods: periods, */ user: action.user, loading: false })
		}
		case changeLangAction: {
			return Object.assign({}, state, {
				language: action.code,
			})
		}
		case SAVESETTINGS:
			return Object.assign({}, state, {
				saved: action.saved
			})
		default:
			return state
	}

}