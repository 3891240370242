import React, { useEffect, useState, Fragment } from 'react';
import { AppBackground } from 'Styles/containerStyle';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdministrationRoute from 'Routes/AdministrationRoute';
import Header from 'Components/Header';
// import Footer from 'Components/Footer';
import cookie from 'react-cookies';
import { useDispatch } from 'Hooks';
import { getSettings } from 'Redux/settings';
import { CircularLoader, MapContainer } from 'Components';

const Building = React.lazy(() => import('Routes/Building'));
const UserManual = React.lazy(() => import('Routes/UserManual'));
const OrderRapport = React.lazy(() => import('Routes/OrderRapport'));
const Support = React.lazy(() => import('Routes/Support'));
const Room = React.lazy(() => import('Routes/Room'));
const Settings = React.lazy(() => import('Routes/Settings'));

function Container(props) {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const getSetting = async () => dispatch(await getSettings())

		const loadSettings = async () => {
			await getSetting()
			setLoading(false)
		}
		loadSettings()
	}, [dispatch])

	return (
		cookie.load('SESSION') ?
			<Fragment>
				<Header title={props.title} />
				<AppBackground color={'blue'}>
					{!loading ?
						<Switch>
							<Route path={'/administration'}>
								<AdministrationRoute />
							</Route>
							<Route path={'/settings'}>
								<Settings />
							</Route>
							<Route path={'/usermanual'}>
								<UserManual />
							</Route>
							<Route path={'/rapport'}>
								<OrderRapport />
							</Route>
							<Route path={'/support'}>
								<Support />
							</Route>
							<Route path={'/building/:buildingUuid/room/:roomUuid'}>
								<Room />
							</Route>
							<Route path={'/building/:uuid'}>
								<Building />
							</Route>
							<Route exact path={'/'}>
								<MapContainer />
							</Route>
							<Redirect path={'*'} to={'/'}></Redirect>
						</Switch>
						: <CircularLoader fill style={{ marginTop: 500 }} />}
				</AppBackground>
				{/* <Footer /> */}
			</Fragment>
			: <Redirect from={window.location.pathname} to={{
				pathname: '/login', state: {
					prevURL: window.location.pathname
				}
			}} />
	)
}
Container.whyDidYouRender = true;

export default Container
