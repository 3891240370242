import React, { useState } from 'react'
import { DatePicker } from '@material-ui/pickers';
import { Dialog, DialogTitle, DialogContent, FormControlLabel, DialogActions, Button, RadioGroup, Radio, FormControl } from '@material-ui/core';
import { ItemG, Caption } from 'Components';
import moment from 'moment'
import { DateRange, AccessTime, KeyboardArrowRight, KeyboardArrowLeft } from 'variables/icons';
import { useLocalization } from 'Hooks';


const CustomDateTime = (props) => {
	const t = useLocalization()
	const { openCustomDate, handleCloseDialog, timeType, handleCancelCustomDate, from, to } = props

	const [endDate, setEndDate] = useState(to ? moment(to) : moment().endOf('day'))
	const [startDate, setStartDate] = useState(from ? moment(from) : moment().subtract(7, 'days').startOf('day'))
	const [time, setTime] = useState(timeType !== undefined ? timeType : 2)

	return <Dialog
		open={openCustomDate}
		onClose={handleCancelCustomDate}
		aria-labelledby='alert-dialog-title'
		aria-describedby='alert-dialog-description'>
		<DialogTitle disableTypography id='alert-dialog-title'>{t('main.custom')}</DialogTitle>
		<DialogContent style={{ maxWidth: 240 }}>
			<ItemG container spacing={2}>
				<ItemG xs={12}>
					<DatePicker
						autoOk
						ampm={false}
						label={t('main.startDate')}
						clearable
						format='LLL'
						value={startDate}
						onChange={e => setStartDate(e)}
						animateYearScrolling={false}
						color='primary'
						disableFuture
						dateRangeIcon={<DateRange />}
						timeIcon={<AccessTime />}
						rightArrowIcon={<KeyboardArrowRight />}
						leftArrowIcon={<KeyboardArrowLeft />}

					/>
				</ItemG>
				<ItemG xs={12}>
					<DatePicker
						autoOk
						disableFuture
						ampm={false}
						label={t('main.endDate')}
						clearable
						format='LLL'
						value={endDate}
						onChange={e => setEndDate(e)}
						animateYearScrolling={false}
						dateRangeIcon={<DateRange />}
						timeIcon={<AccessTime />}
						color='primary'
						rightArrowIcon={<KeyboardArrowRight />}
						leftArrowIcon={<KeyboardArrowLeft />}

					/>
				</ItemG>

				<ItemG style={{ marginTop: 20 }} xs={12}>
					<Caption>{t('main.display')}</Caption>
				</ItemG>
				<ItemG xs={12}>
					<FormControl component="fieldset" /* className={classes.formControl} */>
						<RadioGroup
							aria-label={t('main.display')}
							name={t('main.display')}
							onChange={e => setTime(e.target.value)}
							value={time.toString()}
						>
							{/* <FormControlLabel
								disabled
								value={'0'}
								control={<Radio />}
								label={t('main.minutely')}
							/> */}

							<FormControlLabel
								value={'1'}
								control={<Radio />}
								label={t('main.hourly')}
							/>

							<FormControlLabel
								value={'2'}
								control={<Radio />}
								label={t('main.daily')}
							/>

							{/* <FormControlLabel
									value={'3'}
									control={<Radio />}
									label={t('main.summary')}
								/> */}
						</RadioGroup>
					</FormControl>
				</ItemG>
			</ItemG>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleCancelCustomDate} color='primary'>
				{t('main.close')}
			</Button>
			<Button onClick={() => handleCloseDialog(endDate, startDate, parseInt(time, 10))} color='primary' autoFocus>
				{t('main.apply')}
			</Button>
		</DialogActions>
	</Dialog>
}

export default CustomDateTime