import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Paper, IconButton, ButtonGroup, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { getRules, deleteRule } from 'data/climaid';
import adminStyles from 'Styles/adminStyles';
import CircularLoader from 'Components/Loaders/CircularLoader';
import ConfirmDialog from 'Components/Dialogs/ConfirmDialog';

const AdminAlarmsList = (props) => {
	const classes = adminStyles();
	const history = props.history;

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [selectedUuid, setSelectedUuid] = useState(null);
	const [loading, setLoading] = useState(true);
	const [alarms, setAlarms] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const data = await getRules();

			if (data?.result) {
				setAlarms(data.result);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	const confirmDelete = (uuid) => {
		setSelectedUuid(uuid);
		setShowDeleteDialog(true);
	}

	const handleCancel = () => {
		setShowDeleteDialog(false);
	}

	const handleOk = async () => {
		setLoading(true);

		const result = await deleteRule(selectedUuid);

		if (result) {
			const data = await getRules();

			if (data?.result) {
				setAlarms(data.result);
			}

			setShowDeleteDialog(false);
		}

		setLoading(false);
	}

	return (
		<Paper elevation={3} className={classes.adminPaperContainer}>
			<div className={classes.adminHeader}>Alarmer</div>

			<Button
				variant="contained"
				color="primary"
				startIcon={<AddIcon />}
				onClick={() => history.push('/administration/alarms/add')}
				style={{ marginBottom: 20 }}
			>
				Tilføj alarm
			</Button>

			{!loading ?
				!alarms.length ? <Typography>Der blev ikke fundet nogen alarmer</Typography> :
					<>
						<TableContainer component={Paper}>
							<Table stickyHeader className={classes.table} aria-label="users table">
								<TableHead>
									<TableRow className={classes.tableRow}>
										<TableCell>Navn</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{alarms.map(alarm => (
										<TableRow hover key={alarm.uuid} className={classes.tableRow}>
											<TableCell>
												{alarm.name}
											</TableCell>
											<TableCell align="right">
												<ButtonGroup>
													<IconButton onClick={() => history.push('/administration/alarms/' + alarm.uuid + '/edit')}>
														<EditIcon />
													</IconButton>
													<IconButton onClick={() => confirmDelete(alarm.uuid)}>
														<DeleteIcon />
													</IconButton>
												</ButtonGroup>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<ConfirmDialog visible={showDeleteDialog} title='Dette vil slette alarmen' text='Er du sikker?' handleCancel={handleCancel} handleOk={handleOk} />
					</>
				: <CircularLoader fill />}
		</Paper>
	);
}

export default AdminAlarmsList;