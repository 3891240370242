import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Hidden, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useParams } from 'react-router-dom';

import { LoginWrapper, MobileContainer, ImgLogo, Footer, FooterText, InputContainer, LeftPanel, LoginConfirmButton, LoginTF } from 'Styles/loginStyles';
import { useEventListener, useLocalization } from 'Hooks';
import { confirmUser } from 'data/login';
import { ItemG } from 'Components';
import LoginImages from 'Components/Custom/Login/NewLoginImages';
import { loginTheme } from 'Styles/themes';
import logo from 'assets/logo.png'

const ConfirmUser = () => {
	const history = useHistory();
	const { token } = useParams();

	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const t = useLocalization()

	const handleConfirmUser = async () => {
		let success = true;

		setErrorMsg('');

		if (password === '' && passwordConfirm === '') {
			success = false;
			setErrorMsg('Kodeord må ikke være blankt!');
		} else if (password.length < 8) {
			success = false;
			setErrorMsg('Kodeord skal være på mindst 8 tegn!');
		} else if (password !== passwordConfirm) {
			success = false;
			setErrorMsg('De indtastede kodeord er ikke ens!');
		}

		if (success) {
			const result = await confirmUser({ token: token, newPassword: password });

			if (!result || result !== 200) {
				setErrorMsg('Brugeren eksisterer ikke længere, eller adgangen er udløbet eller spærret!');
			} else {
				history.push('/login');
			}
		}
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleConfirmUser();
		}
	};

	useEventListener('keypress', handleKeyPress);

	return (
		<ThemeProvider theme={loginTheme}>
			<LoginWrapper>
				<ItemG xs={12} sm={12} md={4} lg={4} xl={3} container>
					<MobileContainer>
						<LeftPanel>
							<InputContainer>
								<ItemG xs={12} container justifyContent={'center'}>
									<ImgLogo src={logo} alt='' />
								</ItemG>

								{errorMsg.length ? <Typography align='center' color='error' style={{ marginTop: 20 }}>{errorMsg}</Typography> : ''}

								<ItemG xs={12} container justifyContent={'center'}>
									<LoginTF
										id="password"
										type="password"
										autoFocus
										onChange={(event) => setPassword(event.target.value)}
										label="Kodeord"
										variant="outlined"
										error={errorMsg.length}
										fullWidth
									/>
								</ItemG>

								<ItemG xs={12} container justifyContent={'center'}>
									<LoginTF
										id="passwordConfirm"
										type="password"
										onChange={(event) => setPasswordConfirm(event.target.value)}
										label="Bekræft kodeord"
										variant="outlined"
										error={errorMsg.length}
										fullWidth
									/>
								</ItemG>

								<ItemG xs={12} container justifyContent={'center'}>
									<LoginConfirmButton variant={'contained'} fullWidth color={'secondary'} onClick={handleConfirmUser}>
										Bekræft og log ind
									</LoginConfirmButton>
								</ItemG>
							</InputContainer>
							<Footer xs={12} container alignItems={'flex-end'} justifyContent={'center'}>
								<FooterText>
									{`${t('login.footer')} `}
								</FooterText>
							</Footer>
						</LeftPanel>
					</MobileContainer>
				</ItemG>
				<Hidden smDown>
					<ItemG md={8} lg={8} xl={9}>
						<LoginImages />
					</ItemG>
				</Hidden>
			</LoginWrapper>
		</ThemeProvider>
	)
}

export default ConfirmUser;